<template>
    <!-- Modal -->
    <div
        id="login_modal"
        class="modal fade summary-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="summary-modalLabel"
        aria-hidden="true">
        <div
            class="modal-dialog"
            role="document">
            <div class="modal-content">
                <div class="modal-body login">
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="removeErrors()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <button
                        v-if="currentStep !== 'LANDED_LOGIN'"
                        type="button"
                        class="back"
                        @click="goToFirstStep">
                        <span aria-hidden="true"><i class="pratilipi-icons">&#xe818;</i></span>
                    </button>
                    <div class="login-menu">
                        <a
                            v-if="currentStep === 'REGISTRATION'"
                            href="#"
                            class="signup active"
                            data-tab="signup">__("user_sign_up")</a>
                        <a
                            v-else
                            href="#"
                            class="signin active"
                            data-tab="signin">
                            <!-- __("user_sign_in") -->
                        </a>
                    </div>
                    <Login :open-forgot-password-in-tab="true"/>
                    <Register
                        :current-step="currentStep"
                        :change-current-step="changeCurrentStep"
                        :open-forgot-password-in-tab="true"
                        :should-remove-error="shouldRemoveError"
                        :reset-should-remove-error="resetShouldRemoveError"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import Login from '@/components/Login';
    import Register from '@/components/AppLogin';
    import mixins from '@/mixins';

    export default {
        name: 'LoginModal',
        components: {
            Login,
            Register
        },
        mixins: [
            mixins
        ],
        data () {
            return {
                email: '',
                password: '',
                currentStep: 'LANDED_LOGIN',
                shouldRemoveError: false
            };
        },
        computed: {
            ...mapGetters([
                'getUserDetails',
                'getLoginSource',
                'getSignupSource',
                'getPostLoginAction'
            ])
        },
        watch: {
            'getUserDetails.isGuest' (value) {
                if (!value) {
                    $('#login_modal').modal('hide');
                    $('.overlay, .overlay-1, .overlay-2').hide();

                    const referDetails = localStorage.getItem('login_modal_refer_details') ? JSON.parse(localStorage.getItem('login_modal_refer_details')) : {};

                    const experimentId = 'CONTROL';
                    switch (this.getLoginSource) {
                        case 'EMAIL':
                            this.triggerAnanlyticsEvent('SIGNINSUC_EMAIL_GLOBAL', referDetails.EXPERIMENT_ID ? referDetails.EXPERIMENT_ID : experimentId, {
                                'USER_ID': this.getUserDetails.userId,
                                ...referDetails
                            });
                            break;
                    }

                    switch (this.getSignupSource) {
                        case 'EMAIL':
                            this.triggerAnanlyticsEvent('SIGNUPSUC_EMAIL_GLOBAL', referDetails.EXPERIMENT_ID ? referDetails.EXPERIMENT_ID : experimentId, {
                                'USER_ID': this.getUserDetails.userId,
                                ...referDetails
                            });
                            break;
                        case 'FACEBOOK':
                            this.triggerAnanlyticsEvent('SIGNUPSUC_FACEBOOK_GLOBAL', referDetails.EXPERIMENT_ID ? referDetails.EXPERIMENT_ID : experimentId, {
                                'USER_ID': this.getUserDetails.userId,
                                ...referDetails
                            });
                            break;
                        case 'GOOGLE':
                            this.triggerAnanlyticsEvent('SIGNUPSUC_GOOGLE_GLOBAL', referDetails.EXPERIMENT_ID ? referDetails.EXPERIMENT_ID : experimentId, {
                                'USER_ID': this.getUserDetails.userId,
                                ...referDetails
                            });
                            break;
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                'loginUser'
            ]),
            changeCurrentStep (step) {
                this.currentStep = step;
            },
            goToFirstStep () {
                this.currentStep = 'LANDED_LOGIN';
            },
            removeErrors () {
                this.shouldRemoveError = true;
                const referDetails = localStorage.getItem('login_modal_refer_details') ? JSON.parse(localStorage.getItem('login_modal_refer_details')) : {};
                if (this.$route.meta.store === 'readerv2page' && referDetails.REFER_ACTION === 'CLICKBOOK' && this.getCookie('bucket_id') >= 25 && this.getCookie('bucket_id') <= 49) {
                    this.setCookie('CLICKBOOK_MODAL_CLOSED', 'false', 1, '/');
                }
                if (this.$route.meta.store === 'readerv3page' && referDetails.REFER_ACTION === 'CLICKBOOK' && this.getCookie('bucket_id') >= 25 && this.getCookie('bucket_id') <= 49) {
                    this.setCookie('CLICKBOOK_MODAL_CLOSED', 'false', 1, '/');
                }
            },
            resetShouldRemoveError () {
                this.shouldRemoveError = false;
            }
        }
    };

</script>

<style lang="scss" scoped>
div#login_modal {
    margin-top: 40px;
}
button.close {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 15;
}
button.back {
    position: absolute;
    left: 10px;
    border: none;
    background: transparent;
    top: 5px;
    i {
        text-shadow: 0 1px 0 #fff;
        font-size: 16px;
    }
}
.modal-body.login {
    padding: 0 10px;
    max-height: initial;
}
.login-menu {
    padding: 8px 0 0;
    text-align: center;
    overflow: hidden;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    a {
        color: #555;
        font-size: 13px;
        border-bottom: 2px solid #fff;
        padding: 5px 5px 11px;
        span {
            font-size: 12px;
            font-weight: bold;
            padding: 0 2px;
            margin-right: 5px;
            display: inline-block;
            text-align: center;
        }
        &.active {
            color: #d0021b;
            span {
                color: #d0021b;
            }
        }
        &:hover {
            text-decoration: none;
            color: #d0021b;
        }
    }
}
.forms {
    margin: 15px 10px;
    text-align: left;
    display: none;
    &#signup {
        display: block;
    }
    .or {
        display: flex;
        flex: 1;
        width: 100%;
        margin: 20px auto;
        line-height: 1em;
        font-size: 12px;
    }
    .or::before, .or::after {
        content: '';
        flex-grow: 1;
        margin: 0px 10px;
        background: linear-gradient(to right, #e9e9e9, #e9e9e9);
        background-size: 100% 1px;
        background-position: 0% 50%;
        background-repeat: repeat-x;
    }
    .or::before {
        margin-left: 0;
    }
    .or::after {
        margin-right: 0;
    }
    .forgot-pass, .footlink {
        font-size: 12px;
        margin-right: 10px;
        color: #212529;
    }
    .footlink {
        display: inline-block;
        margin: 10px 0;
    }
    .terms-section {
        font-size: 12px;
        display: block;
        margin: 10px 0;
        color: #212529;
    }
}
.sign-in {
    background: #d00b12;
    color: #fff;
    margin-right: 10px;
    &:hover {
        opacity: 0.9;
    }
}
#forgotPassModal {
    text-align: left;
    margin-top: 150px;
}
.social-login {
    button.fb, button.google {
        background: #3b5998;
        color: #fff;
        display: block;
        width: 100%;
        height: 45px;
        max-width: 400px;
        margin: 10px auto;
        border-radius: 3px;
        border: 0;
        outline: none;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }
        .fa-icon {
            vertical-align: middle;
            margin: 0 15px;
        }
    }
    button.google {
        background: #DD4B39;
    }
}
</style>
