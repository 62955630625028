<template>
    <div
        v-if="isLoginPage"
        id="forgotPassModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="forgotPassModalLabel"
        aria-hidden="true">
        <div
            class="modal-dialog"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        id="forgotPassModalLabel"
                        class="modal-title">__("user_forgot_password")</h5>
                    <button
                        type="button"
                        class="close"
                        :class="this.currentLocale === 'ur' ? 'ml-20-neg' : ''"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="password_reset">
                        <div class="form-group">
                            <input
                                :value="forgotten_password_email"
                                type="email"
                                :dir="this.currentLocale === 'ur' ? 'ltr': ''"
                                class="form-control email-input-for-forget"
                                placeholder="__('user_email')"
                                required
                                @input="forgotten_password_email = $event.target.value"
                                @keydown.enter="triggerEventAndSendPasswordResetEmail(forgotten_password_email)">
                        </div>
                        <button
                            type="button"
                            class="btn sign-in"
                            @click="triggerEventAndSendPasswordResetEmail(forgotten_password_email)">__("user_reset_password")</button>
                    </div>
                </div>
                <div
                    v-if="getForgotPasswordUpdateState === 'LOADING'"
                    class="spinner-overlay">
                    <Spinner/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import Spinner from '@/components/Spinner.vue';

    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            Spinner
        },
        mixins: [
            mixins
        ],
        props: {
            email: {
                type: String,
                required: false
            },
            isLoginPage: {
                type: Boolean,
                required: false
            }
        },
        data () {
            return {
                forgotten_password_email: '',
                currentLocale: '',
            };
        },
        methods: {
            ...mapActions([
                'sendPasswordResetEmail'
            ]),
            triggerEventAndSendPasswordResetEmail (data) {
                this.triggerAnanlyticsEvent('REQUESTPASSWORD_EMAIL_FORGOTP', 'CONTROL', {});
                this.sendPasswordResetEmail(data);
            }
        },
        computed: {
            ...mapGetters([
                'getForgotPasswordUpdateState',
                'getUserDetails'
            ])
        },
        watch: {
            'email' (newEmail) {
                this.forgotten_password_email = newEmail;
            }
        },
        created () {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
        },
        mounted () {

        }
    };
</script>

<style lang="scss" scoped>
#forgotPassModal {
    .forms {
        margin: 15px 10px;
        text-align: left;
        display: none;
        .forgot-pass {
            font-size: 12px;
            margin-right: 10px;
            color: #212529;
        }
    }
    .sign-in {
        background: #d00b12;
        color: #fff;
        margin-right: 10px;
        &:hover {
            opacity: 0.9;
        }
    }
    .spinner-overlay {
        position: absolute;
        top: 0;
        background: rgba(255,255,255,0.8);
        width: 100%;
        height: 100%;
        .spinner {
            top: 42%;
            position: absolute;
        }
    }
    .ml-20-neg {
        margin-left: -20px;
    }
}
</style>
