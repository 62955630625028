<template>
    <button
        v-if="directBtn"
        :class="{direct: directBtn ,'text-right': this.currentLocale === 'ur'}"
        type="button"
        name="button"
        class="fb"
        @click="loginToFacebookDirect"><icon name="facebook-f"/>__("user_sign_up_with_facebook")</button>
    <div
        v-else-if="continueWith"
        id="spinner"
        class="fb-btn-wrapper">
        <div
            class="fb-login-button"
            :class="this.currentLocale === 'ur' ? 'text-right' : ''"
            data-width="300"
            data-max-rows="1"
            data-size="large"
            data-button-type="continue_with"
            data-use-continue-as="true"
            data-show-faces="false"
            data-onlogin="loginToFbContinue"
            data-scope="public_profile,email,user_birthday"
        />
    </div>
    <button
        v-else
        type="button"
        :class="this.currentLocale === 'ur' ? 'text-right' : ''"
        name="button"
        class="fb"
        @click="loginToFacebook"><icon name="facebook-f"/>facebook</button>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import inViewport from 'vue-in-viewport-mixin';
    import mixins from '@/mixins';

    export default {
        name: 'FacebookLoginButton',
        mixins: [
            mixins,
            inViewport
        ],
        data() {
            return {
                currentLocale: '',
            }
        },
        props: {
            directBtn: {
                type: Boolean
            },
            continueWith: {
                type: Boolean
            },
            'in-viewport-once': {
                default: true
            },
            'in-viewport-offset-top': {
                default: -90
            }
        },
        methods: {
            ...mapActions([
                'loginUserWithFacebookAccessToken'
            ]),
            loginToFacebookDirect () {
                localStorage.setItem('login_modal_refer_details', JSON.stringify({
                    REFER_SCREEN: 'BOOK',
                    REFER_ACTION: 'CLICKED',
                    REFER_LOCATION: 'FACEBOOK'

                }));
                this.triggerAnanlyticsEvent(`CLICKED_FACEBOOK_BOOK`, 'CONTROL', {});
                this.loginToFacebook();
            },
            loginToFacebook () {
                const that = this;
                FB.login(function (fbResponse) {
                    if (fbResponse == null || fbResponse.authResponse == null) {
                        return;
                        this.triggerAnanlyticsEvent('LOGIN_FB_FAILURE');
                    }
                    this.triggerAnanlyticsEvent('LOGIN_FB_SUCCESS');
                    that.loginUserWithFacebookAccessToken({ facebookAccessToken: fbResponse.authResponse.accessToken, language: that.getCurrentLanguage().fullName.toUpperCase() });
                }, { scope: 'public_profile,email,user_birthday' });
            },
            finished_rendering () {
                setTimeout(() => {
                    $('#spinner').removeClass('fb-btn-wrapper');
                }, 2000);
            }
        },
        watch: {
            'continueWith' (value) {
                if (value) {
                    setTimeout(() => {
                        FB.XFBML.parse();
                        FB.Event.subscribe('xfbml.render', this.finished_rendering);
                    }, 500);
                }
            },
            'inViewport.now' (visible) {
                if (visible && this.directBtn) {
                    this.triggerAnanlyticsEvent(`VIEWED_FACEBOOK_BOOK`, 'CONTROL', {});
                }
            }
        },

        created () {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
            if (this.continueWith && (this.getCookie('SIGNUP_DRAWER_VIEWED') == null || this.getCookie('SIGNUP_DRAWER_VIEWED') == 'true')) {
                setTimeout(() => {
                    this.triggerAnanlyticsEvent(`VIEWED_SIGNUP_BOOK`, 'CONTROL', {});
                    FB.XFBML.parse();
                    FB.Event.subscribe('xfbml.render', this.finished_rendering);
                }, 1000);
            }

            window.loginToFbContinue = (data) => {
                localStorage.setItem('login_modal_refer_details', JSON.stringify({
                    REFER_SCREEN: 'BOOK',
                    REFER_ACTION: 'CLICKED',
                    REFER_LOCATION: 'SIGNUP'
                }));
                this.triggerAnanlyticsEvent(`CLICKED_SIGNUP_BOOK`, 'CONTROL', {});

                if (data.status == 'connected') {
                    this.loginUserWithFacebookAccessToken({ facebookAccessToken: data.authResponse.accessToken, language: this.getCurrentLanguage().fullName.toUpperCase() });
                }
            };
        }
    };
</script>

<style  lang="scss" scoped>
button.fb, button.google {
    background: #3b5998;
    color: #fff;
    display: block;
    width: 100%;
    height: 45px;
    max-width: 400px;
    margin: 10px auto;
    border-radius: 3px;
    border: 0;
    outline: none;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    .fa-icon {
        vertical-align: middle;
        margin: 0 15px;
    }
}
button.google {
    background: #DD4B39;
}
button.fb.direct {
    max-width: 96%;
    margin: 0 auto;
    text-align: center;
    .fa-icon {
        margin: 0 15px 0 0;
    }
}
.fb-btn-wrapper {
    background: #4267b2;
    border-radius: 5px;
    color: white;
    height: 40px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    min-width: 250px;
    max-width: 300px;
    border-radius: 4px;
}
</style>
