var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentStep === 'LANDED_LOGIN')?_c('form',{staticClass:"forms",attrs:{"id":"signup"}},[_c('div',{staticClass:"pratilipi-logo"},[_c('img',{attrs:{"src":"https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/comics-logo-copy-04.png"}}),_c('br'),(
                        _vm.getPostLoginAction.action &&
                            _vm.getPostLoginAction.action.indexOf('addToLibrary') >
                                -1
                    )?_c('span',[_vm._v("__('login_popup_story_added_to_library')")]):(
                        _vm.getPostLoginAction.action &&
                            _vm.getPostLoginAction.action.indexOf('follow') > -1
                    )?_c('span',[_vm._v("__(\"login_popup_follow_unfollow\")")]):(
                        _vm.getPostLoginAction.action &&
                            _vm.getPostLoginAction.action.indexOf(
                                'saveOrUpdateReview'
                            ) > -1
                    )?_c('span',[_vm._v("__(\"login_popup_read_and_rate_stories\")")]):_c('span',[_vm._v("Login with")])]),_c('div',{staticClass:"social-login"},[_c('FacebookLogin',{class:{ 'continue-with-fb' : _vm.continueWith },attrs:{"continue-with":_vm.continueWith}}),_c('GoogleLogin')],1),_c('div',{staticClass:"or"},[_vm._v("or")]),(_vm.getLoginError && _vm.getLoginError.message)?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.message)?_c('span',[_vm._v(_vm._s(_vm._f("getTranslatedLoginErrorMessage")(_vm.getLoginError.message)))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[(
                        _vm.emailIsInvalid || (_vm.getLoginError && _vm.getLoginError.email)
                    )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.email)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.email))]):_c('span',[_vm._v("email entered incorrectly")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                        error:
                            _vm.emailIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.email)
                    },attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"type":"email","placeholder":"email"},domProps:{"value":(_vm.email)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.checkEmailAndGoToSecondStep.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('input',{attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"type":"password","hidden":"true"}}),_c('button',{attrs:{"type":"button"},on:{"click":_vm.checkEmailAndGoToSecondStep}},[_c('span',[_vm._v("sign in")])])]),_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.set),expression:"set"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.set)?_vm._i(_vm.set,null)>-1:(_vm.set)},on:{"change":function($event){var $$a=_vm.set,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.set=$$a.concat([$$v]))}else{$$i>-1&&(_vm.set=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.set=$$c}}}}),_vm._v(" Creating an account means you're okay with our "),_c('a',{attrs:{"href":"/terms-of-service"}},[_vm._v(" Term of Service")]),_vm._v(" and "),_c('a',{attrs:{"href":"/privacy-policy"}},[_vm._v("Privacy Policy.")])])])]):_vm._e(),(_vm.currentStep === 'REGISTRATION')?_c('div',{staticClass:"forms",attrs:{"id":"signup-form"}},[(_vm.getLoginError && _vm.getLoginError.message)?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.message)?_c('span',[_vm._v(_vm._s(_vm._f("getTranslatedLoginErrorMessage")(_vm.getLoginError.message)))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[(
                        _vm.nameIsInvalid || (_vm.getLoginError && _vm.getLoginError.name)
                    )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.name)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.name))]):_c('span',[_vm._v("name is required")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],ref:"name",staticClass:"form-control",class:{
                        error:
                            _vm.nameIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.name)
                    },attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"type":"text","placeholder":"full_name"},domProps:{"value":(_vm.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.verifyAndSignupUser({
                            name: _vm.name,
                            email: _vm.email,
                            password: _vm.password,
                            language: _vm.getCurrentLanguage().fullName.toUpperCase()
                        })},"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[(
                        _vm.emailIsInvalid || (_vm.getLoginError && _vm.getLoginError.email)
                    )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.email)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.email))]):_c('span',[_vm._v("email entered incorrectly")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                        error:
                            _vm.emailIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.email)
                    },attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"type":"email","placeholder":"email"},domProps:{"value":(_vm.email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.verifyAndSignupUser({
                            name: _vm.name,
                            email: _vm.email,
                            password: _vm.password,
                            language: _vm.getCurrentLanguage().fullName.toUpperCase()
                        })},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[(
                        _vm.passwordIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.password)
                    )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.password)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.password))]):_c('span',[_vm._v("password length too short")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                        error:
                            _vm.passwordIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.password)
                    },attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"autocomplete":"new-password","type":"password","placeholder":"password"},domProps:{"value":(_vm.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.verifyAndSignupUser({
                            name: _vm.name,
                            email: _vm.email,
                            password: _vm.password,
                            language: _vm.getCurrentLanguage().fullName.toUpperCase()
                        })},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('button',{staticClass:"btn sign-in",attrs:{"type":"button"},on:{"click":function($event){_vm.verifyAndSignupUser({
                        name: _vm.name,
                        email: _vm.email,
                        password: _vm.password,
                        language: _vm.getCurrentLanguage().fullName.toUpperCase()
                    })}}},[_vm._v(" sign_up ")]),_c('div',{staticClass:"or"},[_vm._v("or")]),_c('button',{staticClass:"btn sign-up",attrs:{"type":"button"},on:{"click":function($event){return _vm.goToSignin()}}},[_vm._v(" sign in ")]),_vm._m(0)]):_vm._e(),(_vm.currentStep === 'LOGIN')?_c('div',{staticClass:"forms",attrs:{"id":"signin-form"}},[_c('form',{attrs:{"id":"formToSubmit"}},[(_vm.getLoginError && _vm.getLoginError.message)?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.message)?_c('span',[_vm._v(_vm._s(_vm._f("getTranslatedLoginErrorMessage")(_vm.getLoginError.message)))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[(
                            _vm.emailIsInvalid ||
                                (_vm.getLoginError && _vm.getLoginError.email)
                        )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.email)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.email))]):_c('span',[_vm._v("email entered incorrectly")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                            error:
                                _vm.emailIsInvalid ||
                                (_vm.getLoginError && _vm.getLoginError.email)
                        },attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"type":"email","placeholder":"email"},domProps:{"value":(_vm.email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateAndLoginUser({ email: _vm.email, password: _vm.password })},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[(
                            _vm.passwordIsInvalid ||
                                (_vm.getLoginError && _vm.getLoginError.password)
                        )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.password)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.password))]):_c('span',[_vm._v("password length too short")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password",staticClass:"form-control",class:{
                            error:
                                _vm.passwordIsInvalid ||
                                (_vm.getLoginError && _vm.getLoginError.password)
                        },attrs:{"dir":this.currentLocale === 'ur' ? 'ltr' : '',"type":"password","placeholder":"password"},domProps:{"value":(_vm.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateAndLoginUser({ email: _vm.email, password: _vm.password })},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('button',{staticClass:"btn sign-in",attrs:{"type":"button"},on:{"click":function($event){return _vm.validateAndLoginUser({ email: _vm.email, password: _vm.password })}}},[_vm._v(" sign in ")])])]):_vm._e(),_c('ForgetPassword',{attrs:{"is-login-page":_vm.isLoginPage,"email":_vm.email}}),(_vm.getLoginLoadingState === 'LOADING')?_c('div',{staticClass:"spinner-overlay"},[_c('Spinner')],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"terms-section"},[_vm._v("__(\"register_part_1\") "),_c('a',{attrs:{"href":"/privacy-policy","target":"_blank"}},[_vm._v("__(\"footer_privacy_policy\")")]),_vm._v(" __(\"register_part_2\") "),_c('a',{attrs:{"href":"/terms-of-service","target":"_blank"}},[_vm._v("__(\"footer_terms_of_service\")")]),_vm._v(" __(\"register_part_3\")")])
}]

export { render, staticRenderFns }