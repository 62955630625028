var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"forms",attrs:{"id":"signin"}},[_c('div',{staticClass:"or"},[_vm._v("__(\"or\")")]),_c('form',{attrs:{"id":"formToSubmit"}},[(_vm.getLoginError && _vm.getLoginError.message)?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.message)?_c('span',[_vm._v(_vm._s(_vm._f("getTranslatedLoginErrorMessage")(_vm.getLoginError.message)))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[(
                        _vm.emailIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.email)
                    )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.email)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.email))]):_c('span',[_vm._v("__(\"email_entered_incorrectly\")")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                        error:
                            _vm.emailIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.email)
                    },attrs:{"type":"email","placeholder":"__('user_email')"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[(
                        _vm.passwordIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.password)
                    )?_c('p',{staticClass:"validation_error"},[_c('i',{staticClass:"pratilipi-icons"},[_vm._v("")]),(_vm.getLoginError && _vm.getLoginError.password)?_c('span',[_vm._v(_vm._s(_vm.getLoginError.password))]):_c('span',[_vm._v("__(\"password_minimum\")")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                        error:
                            _vm.passwordIsInvalid ||
                            (_vm.getLoginError && _vm.getLoginError.password)
                    },attrs:{"type":"password","placeholder":"__('user_password')"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('button',{staticClass:"btn sign-in",attrs:{"type":"button"},on:{"click":function($event){return _vm.validateAndLoginUser({ email: _vm.email, password: _vm.password })}}},[_vm._v(" __(\"user_sign_in\") ")]),(!_vm.openForgotPasswordInTab)?_c('a',{staticClass:"forgot-pass",attrs:{"href":"#"},on:{"click":_vm.triggerEventAndOpenForgotPasswordModal}},[_vm._v("__(\"user_forgot_password\")")]):_c('router-link',{staticClass:"forgot-pass",attrs:{"to":'/login#forgot-pass',"target":"_blank"}},[_vm._v("__(\"user_forgot_password\")")]),_c('div',{staticClass:"terms-section"},[_c('span',[_vm._v("__(\"user_is_new\")")]),_c('a',{attrs:{"href":"#","data-tab":"signup"},on:{"click":_vm.tabsignup}},[_vm._v("__(\"user_sign_up_for_pratilipi\")")])])],1)]),(_vm.getLoginLoadingState === 'LOADING')?_c('div',{staticClass:"spinner-overlay"},[_c('Spinner')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }