<template>
    <div>
        <form v-if="currentStep === 'LANDED_LOGIN'" id="signup" class="forms">
            <div class="pratilipi-logo">
                <img src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/comics-logo-copy-04.png" />
                <br />
                <span
                    v-if="
                        getPostLoginAction.action &&
                            getPostLoginAction.action.indexOf('addToLibrary') >
                                -1
                    "
                    >__('login_popup_story_added_to_library')</span
                >
                <span
                    v-else-if="
                        getPostLoginAction.action &&
                            getPostLoginAction.action.indexOf('follow') > -1
                    "
                    >__("login_popup_follow_unfollow")</span
                >
                <span
                    v-else-if="
                        getPostLoginAction.action &&
                            getPostLoginAction.action.indexOf(
                                'saveOrUpdateReview'
                            ) > -1
                    "
                    >__("login_popup_read_and_rate_stories")</span
                >
                <span v-else>Login with</span>
            </div>
            <div class="social-login">
                <FacebookLogin
                    :class="{ 'continue-with-fb' : continueWith }"
                    :continue-with="continueWith"/>
                <GoogleLogin/>
            </div>
            <div class="or">or</div>
            <p
                v-if="getLoginError && getLoginError.message"
                class="validation_error"
            >
                <i class="pratilipi-icons">&#xe802;</i>
                <span v-if="getLoginError && getLoginError.message">{{
                    getLoginError.message | getTranslatedLoginErrorMessage
                }}</span>
            </p>
            <div class="form-group">
                <p
                    v-if="
                        emailIsInvalid || (getLoginError && getLoginError.email)
                    "
                    class="validation_error"
                >
                    <i class="pratilipi-icons">&#xe802;</i>
                    <span v-if="getLoginError && getLoginError.email">{{
                        getLoginError.email
                    }}</span>
                    <span v-else>email entered incorrectly</span>
                </p>
                <input
                    :class="{
                        error:
                            emailIsInvalid ||
                            (getLoginError && getLoginError.email)
                    }"
                    v-model="email"
                    :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                    type="email"
                    class="form-control"
                    placeholder="email"
                    @keydown.enter="checkEmailAndGoToSecondStep"
                />
                <input
                    :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                    type="password"
                    hidden="true"
                />
                <button type="button" @click="checkEmailAndGoToSecondStep">
                    <span>sign in</span>
                </button>
<!--                <a-->
<!--                    v-if="!openForgotPasswordInTab"-->
<!--                    href="#"-->
<!--                    class="forgot-pass"-->
<!--                    @click="triggerEventAndOpenForgotPasswordModal"-->
<!--                    >__("user_forgot_password")</a-->
<!--                >-->
            </div>
            <div class="checkbox">
                <label><input type="checkbox" v-model="set"> Creating an account means you're okay with our <a href="/terms-of-service"> Term of Service</a> and <a href="/privacy-policy">Privacy Policy.</a></label>
            </div>
        </form>

        <div
            v-if="currentStep === 'REGISTRATION'"
            id="signup-form"
            class="forms"
        >
            <p
                v-if="getLoginError && getLoginError.message"
                class="validation_error"
            >
                <i class="pratilipi-icons">&#xe802;</i>
                <span v-if="getLoginError && getLoginError.message">{{
                    getLoginError.message | getTranslatedLoginErrorMessage
                }}</span>
            </p>
            <div class="form-group">
                <p
                    v-if="
                        nameIsInvalid || (getLoginError && getLoginError.name)
                    "
                    class="validation_error"
                >
                    <i class="pratilipi-icons">&#xe802;</i>
                    <span v-if="getLoginError && getLoginError.name">{{
                        getLoginError.name
                    }}</span>
                    <span v-else>name is required</span>
                </p>
                <input
                    ref="name"
                    :class="{
                        error:
                            nameIsInvalid ||
                            (getLoginError && getLoginError.name)
                    }"
                    v-model="name"
                    :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                    type="text"
                    class="form-control"
                    placeholder="full_name"
                    @keyup.enter="
                        verifyAndSignupUser({
                            name,
                            email,
                            password,
                            language: getCurrentLanguage().fullName.toUpperCase()
                        })
                    "
                />
            </div>
            <div class="form-group">
                <p
                    v-if="
                        emailIsInvalid || (getLoginError && getLoginError.email)
                    "
                    class="validation_error"
                >
                    <i class="pratilipi-icons">&#xe802;</i>
                    <span v-if="getLoginError && getLoginError.email">{{
                        getLoginError.email
                    }}</span>
                    <span v-else>email entered incorrectly</span>
                </p>
                <input
                    :class="{
                        error:
                            emailIsInvalid ||
                            (getLoginError && getLoginError.email)
                    }"
                    v-model="email"
                    :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                    type="email"
                    class="form-control"
                    placeholder="email"
                    @keyup.enter="
                        verifyAndSignupUser({
                            name,
                            email,
                            password,
                            language: getCurrentLanguage().fullName.toUpperCase()
                        })
                    "
                />
            </div>
            <div class="form-group">
                <p
                    v-if="
                        passwordIsInvalid ||
                            (getLoginError && getLoginError.password)
                    "
                    class="validation_error"
                >
                    <i class="pratilipi-icons">&#xe802;</i>
                    <span v-if="getLoginError && getLoginError.password">{{
                        getLoginError.password
                    }}</span>
                    <span v-else>password length too short</span>
                </p>
                <input
                    :class="{
                        error:
                            passwordIsInvalid ||
                            (getLoginError && getLoginError.password)
                    }"
                    v-model="password"
                    :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                    autocomplete="new-password"
                    type="password"
                    class="form-control"
                    placeholder="password"
                    @keyup.enter="
                        verifyAndSignupUser({
                            name,
                            email,
                            password,
                            language: getCurrentLanguage().fullName.toUpperCase()
                        })
                    "
                />
            </div>

            <button
                type="button"
                class="btn sign-in"
                @click="
                    verifyAndSignupUser({
                        name,
                        email,
                        password,
                        language: getCurrentLanguage().fullName.toUpperCase()
                    })
                "
            >
                sign_up
            </button>
            <div class="or">or</div>
            <button type="button" class="btn sign-up" @click="goToSignin()">
                sign in
            </button>

            <span class="terms-section"
                >__("register_part_1")
                <a href="/privacy-policy" target="_blank"
                    >__("footer_privacy_policy")</a
                >
                __("register_part_2")
                <a href="/terms-of-service" target="_blank"
                    >__("footer_terms_of_service")</a
                >
                __("register_part_3")</span
            >
        </div>

        <div v-if="currentStep === 'LOGIN'" id="signin-form" class="forms">
            <form id="formToSubmit">
                <p
                    v-if="getLoginError && getLoginError.message"
                    class="validation_error"
                >
                    <i class="pratilipi-icons">&#xe802;</i>
                    <span v-if="getLoginError && getLoginError.message">{{
                        getLoginError.message | getTranslatedLoginErrorMessage
                    }}</span>
                </p>
                <div class="form-group">
                    <p
                        v-if="
                            emailIsInvalid ||
                                (getLoginError && getLoginError.email)
                        "
                        class="validation_error"
                    >
                        <i class="pratilipi-icons">&#xe802;</i>
                        <span v-if="getLoginError && getLoginError.email">{{
                            getLoginError.email
                        }}</span>
                        <span v-else>email entered incorrectly</span>
                    </p>
                    <input
                        :class="{
                            error:
                                emailIsInvalid ||
                                (getLoginError && getLoginError.email)
                        }"
                        v-model="email"
                        :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                        type="email"
                        class="form-control"
                        placeholder="email"
                        @keyup.enter="validateAndLoginUser({ email, password })"
                    />
                </div>
                <div class="form-group">
                    <p
                        v-if="
                            passwordIsInvalid ||
                                (getLoginError && getLoginError.password)
                        "
                        class="validation_error"
                    >
                        <i class="pratilipi-icons">&#xe802;</i>
                        <span v-if="getLoginError && getLoginError.password">{{
                            getLoginError.password
                        }}</span>
                        <span v-else>password length too short</span>
                    </p>
                    <input
                        ref="password"
                        :class="{
                            error:
                                passwordIsInvalid ||
                                (getLoginError && getLoginError.password)
                        }"
                        v-model="password"
                        :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                        type="password"
                        class="form-control"
                        placeholder="password"
                        @keyup.enter="validateAndLoginUser({ email, password })"
                    />
                </div>

                <button
                    type="button"
                    class="btn sign-in"
                    @click="validateAndLoginUser({ email, password })"
                >
                    sign in
                </button>
            </form>
        </div>

        <ForgetPassword :is-login-page="isLoginPage" :email="email" />
        <div v-if="getLoginLoadingState === 'LOADING'" class="spinner-overlay">
            <Spinner />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dataAccessor from "../http/DataAccessor.js";
import GoogleLogin from "@/components/GoogleLogin";
import FacebookLogin from "@/components/FacebookLogin";
import Spinner from "@/components/Spinner.vue";
import ForgetPassword from "@/components/ForgetPasswordModal";
import mixins from "@/mixins";
import $ from 'jquery';

export default {
    name: "RegistrationForm",
    mixins: [mixins],
    props: {
        isLoginPage: {
            type: Boolean,
            required: false
        },
        openForgotPasswordInTab: {
            type: Boolean,
            required: false,
            default: false
        },
        currentStep: {
            required: true,
            type: String
        },
        changeCurrentStep: {
            required: true,
            type: Function
        },
        shouldRemoveError: {
            type: Boolean
        },
        resetShouldRemoveError: {
            type: Function
        }
    },
    data() {
        return {
            email: "",
            password: "",
            name: "",
            emailIsInvalid: false,
            passwordIsInvalid: false,
            nameIsInvalid: false,
            continueWith: false,
            currentLocale: ""
        };
    },
    methods: {
        ...mapActions([
            "signupUser",
            "loginUser",
            "checkIfUserValid",
            "resetLoginError"
        ]),
        goToSignup() {
            this.changeCurrentStep("REGISTRATION");
        },
        goToSignin() {
            this.changeCurrentStep("LOGIN");
        },
        checkEmailAndGoToSecondStep() {
            this.emailIsInvalid = !this.validateEmail(this.email);

            if (this.emailIsInvalid) {
                return;
            }

            this.checkIfUserValid(this.email);
        },
        verifyAndSignupUser(data) {
            const { name, email, password } = data;

            this.nameIsInvalid = !this.validateUsername(name);
            this.emailIsInvalid = !this.validateEmail(email);
            this.passwordIsInvalid = !this.validatePassword(password);

            if (
                !this.emailIsInvalid &&
                !this.passwordIsInvalid &&
                !this.nameIsInvalid
            ) {
                this.signupUser(data);
            }
        },
        validateAndLoginUser({ email, password }) {
            this.emailIsInvalid = !this.validateEmail(email);
            this.passwordIsInvalid = !this.validatePassword(password);
            if (!this.emailIsInvalid && !this.passwordIsInvalid) {
                this.loginUser({ email, password });
            }
        },
        tabsignin(event) {
            event.preventDefault();
            const tab_id = $(event.currentTarget).attr("data-tab");
            $(".login-menu a").removeClass("active");
            $(".signin").addClass("active");
            $(".forms").hide();
            $("#" + tab_id).show();
        },
        triggerEventAndOpenForgotPasswordModal() {
            this.openForgotPasswordModal();
            setTimeout(() => {
                $(".email-input-for-forget").focus();
            }, 500);
            this.triggerAnanlyticsEvent(
                "LANDED_FORGOTPM_FORGOTP",
                "CONTROL",
                {}
            );
        }
    },
    watch: {
        currentStep() {
            this.nameIsInvalid = false;
            this.emailIsInvalid = false;
            this.passwordIsInvalid = false;
            this.resetLoginError();
        },

        "getEmailCheckingStatus.loading_state"(loadingState) {
            if (
                loadingState === "LOADING_SUCCESS" &&
                this.getEmailCheckingStatus.is_valid
            ) {
                this.changeCurrentStep("LOGIN");
                setTimeout(() => {
                    this.$refs.password.focus();
                }, 500);
            } else if (
                loadingState === "LOADING_SUCCESS" &&
                !this.getEmailCheckingStatus.is_valid
            ) {
                if (this.isGammaEnvironment()) {
                    this.changeCurrentStep("REGISTRATION");
                    setTimeout(() => {
                        this.$refs.name.focus();
                    }, 500);
                } else {
                    this.emailIsInvalid = true;
                }
            } else if (loadingState === "LOADING_ERROR") {
                this.emailIsInvalid = true;
            }
        },
        shouldRemoveError(shouldRemoveError) {
            if (shouldRemoveError) {
                this.nameIsInvalid = false;
                this.emailIsInvalid = false;
                this.passwordIsInvalid = false;
                this.resetLoginError();
                this.resetShouldRemoveError();
            }
        },
        "$route.query.screen"(screenName) {
            if (screenName) {
                this.continueWith = true;
            }
        }
    },
    computed: {
        ...mapGetters([
            "getLoginError",
            "getLoginLoadingState",
            "getEmailCheckingStatus",
            "getPostLoginAction"
        ])
    },
    components: {
        GoogleLogin,
        FacebookLogin,
        Spinner,
        ForgetPassword
    },
    created() {
        this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
    }
};
</script>

<style lang="scss" scoped>
.forms {
    margin: 15px 10px;
    text-align: left;
    display: none;
    &#signup {
        display: block;
    }
    &#signup-form {
        display: block;
        .btn {
            width: 100%;
        }
        .btn.sign-up {
            width: 100%;
            background-color: #eee;
        }
    }
    &#signin-form {
        display: block;

        .btn.sign-in {
            margin-top: 10px;
            width: 100%;
        }

        .btn.sign-up {
            width: 100%;
            background-color: #eee;
        }
    }

    .pratilipi-logo {
        text-align: center;
        img {
            display: inline-block;
            height: 25%;
            width: 25%;
            padding-bottom: 10px;
        }

        p.subtitle {
            text-align: center;
            font-size: 12px;
            padding: 10px 0 10px 10px;
            margin-bottom: 5px;
        }
    }
    .or {
        display: flex;
        flex: 1;
        width: 100%;
        margin: 20px auto;
        line-height: 1em;
        font-size: 12px;
    }
    .or::before,
    .or::after {
        content: "";
        flex-grow: 1;
        margin: 0px 10px;
        background: linear-gradient(to right, #e9e9e9, #e9e9e9);
        background-size: 100% 1px;
        background-position: 0% 50%;
        background-repeat: repeat-x;
    }
    .or::before {
        margin-left: 0;
    }
    .or::after {
        margin-right: 0;
    }

    .form-group {
        position: relative;
        i {
            display: inline-block;
            vertical-align: middle;
        }

        input {
            padding-right: 30%;
        }
        button {
            vertical-align: middle;
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
            color: #d00b12;
            padding: 5px;
            background: none;
            border: none;

            span {
                font-size: 14px;
            }
        }
    }
    .forgot-pass,
    .footlink {
        font-size: 12px;
        color: #212529;
    }
    .forgot-pass {
        float: right;
        padding-top: 5px;
    }
    .footlink {
        display: inline-block;
        margin: 10px 0;
    }
    .terms-section {
        font-size: 12px;
        display: block;
        margin: 10px 0;
        color: #212529;
    }
}
.sign-in {
    background: #d00b12;
    color: #fff;
    margin-right: 10px;
    &:hover {
        opacity: 0.9;
    }
}
#forgotPassModal {
    text-align: left;
    margin-top: 150px;
}
.social-login {
    button.fb,
    button.google {
        background: #3b5998;
        color: #fff;
        display: block;
        width: 100%;
        height: 45px;
        max-width: 302px;
        margin: 10px auto;
        border-radius: 3px;
        border: 0;
        outline: none;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }
        .fa-icon {
            vertical-align: middle;
            margin: 0 15px;
        }
    }
    .continue-with-fb {
        display: block;
        margin-top: 10px;
        text-align: center;
    }
    button.google {
        background: #dd4b39;
    }
}
.validation_error {
    margin: 5px 5px 5px 0;
    font-size: 12px;
    color: #d00b12;
    i {
        font-size: 16px;
        vertical-align: middle;
    }
    span {
        vertical-align: middle;
    }
}
.form-control.error {
    border-color: #d00b12;
}
.spinner-overlay {
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    .spinner {
        top: 42%;
        position: absolute;
    }
}
</style>
